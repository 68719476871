<template>
  <div v-if="!state.loggedIn">
    ログイン中...
  </div>
  <div v-if="state.loggedIn">
    <p>loggedIn: {{ state.loggedIn }}</p>
    <p>displayName: {{ state.displayName }}</p>
    <p>occoredError: {{ state.occoredError }}</p>
    <p>profile: {{ state.profile }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from "vue";
// import HelloWorld from "./components/HelloWorld.vue";
import liff from "@line/liff";

export default defineComponent({
  name: "App",
  // components: {
  //   HelloWorld,
  // },
  setup() {
    const state = reactive({
      loggedIn: false,
      profile: {},
      displayName: "init",
      occoredError: "",
    });

    onMounted(async () => {
      liff
        .init({ liffId: "1656353193-aby4ExjV" })
        .then(() => {
          state.loggedIn = liff.isLoggedIn();
          state.displayName = "then"
          getProfile();
        })
        .catch((err) => {
          // Error happens during initialization
          state.occoredError = "error:" + err;
        });
    });

    const getProfile = () => {
          state.displayName = "getProfile"
      liff.getProfile().then((profile) => {
        state.displayName = "profile"
        state.profile = profile;
      });
    };

    return {
      state,
    };
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
